import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Title from "../elements/title.tsx";
import Images from "../elements/images.tsx";
import Annotation from "../elements/annotation.tsx";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src="../../../wemagine/wemaginelogo.png" />
    <h5>{`A business exploration on the viability of dynamic office art`}</h5>
    <Title mdxType="Title">
  <h3>Concept</h3>
    </Title>
    <Text mdxType="Text">
  Wemagine is a business idea that co-create the wall art by interacting system
  through speech.{" "}
  <b>
    The project mainly focuses on the hypothesis-driven design process to test
    out the feasibility, viability and desirability of a business idea.{" "}
  </b>
  Wemagine service utilises DALLE-2, which is an AI system that can create realistic
  images and art from a description in natural language (OpenAi, n.d.).
  <Gap margin={10} mdxType="Gap" />
 
    </Text>
    <img src="../../../wemagine/concept.png" />
    <Annotation mdxType="Annotation">Co-create the office wall art through speech by interacting with wemagine system </Annotation>
 <img src="../../../wemagine/Tech.png" />
 <Annotation mdxType="Annotation">System interaction among users, wemagine service and DALLE-2</Annotation>
 
 <Title mdxType="Title">
  <h3>Business Model Canvas</h3>
    </Title>
    <img src="../../../wemagine/businessmodel.jpeg" />
    <Title mdxType="Title">
  <h3>Problem-Solution Fit</h3>
    </Title>
    <h5>Landing page test for customers' insights</h5>
    <img src="../../../wemagine/landing.gif" />
    <Annotation mdxType="Annotation">Landing page on a web server</Annotation>
    <Text mdxType="Text">
  We conducted a landing page experiment to find out if customers would be
  willing to pay more for extra perks. By tracking which button is clicked by
  visitors, we gained insights as to which packages of our products customers
  would be most interested in.
    </Text>
    <Title mdxType="Title">
  <h3>Go to Market Plan</h3>
    </Title>
    <h5>Get-Keep-Grow strategy</h5>
    <AliceCarousel animationDuration="2000" infinite controlsStrategy="alternate" autoPlay mouseTracking disableButtonsControls items={[<img src="../../../wemagine/market1.jpeg" onDragStart={e => {
      e.preventDefault();
    }} role="presentation" />, <img src="../../../wemagine/market2.jpeg" onDragStart={e => {
      e.preventDefault();
    }} role="presentation" />, <img src="../../../wemagine/market3.jpeg" onDragStart={e => {
      e.preventDefault();
    }} role="presentation" />]} mdxType="AliceCarousel" />
    <h5>Billboard Advertisement</h5>
    <img src="../../../wemagine/billboard.png" />
    <Text mdxType="Text">
  Additionally, we co-advertise with other brands on billboards, where content
  of these brands will be displayed, whilst viewers can vote for the content, by
  scanning the QR-code on the billboard.
    </Text>
    <Title mdxType="Title">
  <h3>Ecosystem Positioning</h3>
    </Title>
    <img src="../../../wemagine/system.png" />
    <Text mdxType="Text">
  Stakeholders are marketing vendors, OpenAi, screen and microphone supplier,
  business offices,and office workers.
    </Text>
    <Title mdxType="Title">
  <h3>Financial Model for the first 24 months</h3>
    </Title>
    <img src="../../../wemagine/finance.jpeg" />
    <img src="../../../wemagine/future.jpeg" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      